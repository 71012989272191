import React from 'react'

const AddBanner = () => {
  return (
    <div>
      Add banners here...
    </div>
  )
}

export default AddBanner;
